import React, { useState } from "react";
import Navbar from "../signin/Navbar.tsx"; // Import existing Navbar component
import LeftPanel from "../signin/LeftPanel.tsx"; // Import existing LeftPanel component
import { Link, useNavigate } from "react-router-dom";
import { InputAdornment, TextField } from "@mui/material";
import { Email } from "@mui/icons-material";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission
  const navigate = useNavigate();


  const handleSubmit = () => {
    if (!email.includes("@")) {
      setEmailError("Enter a valid Email ID");
    } else {
      setEmailError("");
      setIsSubmitted(true); // Change the state to render the confirmation message
    }
  };

const handleBackToLogin = ()=>{
  setIsSubmitted(false)
  navigate("/signin")
}

  return (
    <div className="h-screen flex flex-col">
      {/* Reuse Navbar */}
      <Navbar />

      <div className="flex flex-1">
        {/* Reuse LeftPanel */}
        <div className="w-1/2">
          <LeftPanel />
        </div>

        {/* Right Panel */}
        <div className="w-1/2 flex flex-col justify-center p-8 px-8 md:px-32">
          {isSubmitted ? (
            // Content to display after form submission
            <div className="text-center">
              <h2 className="text-[44px] font-bold mb-4 text-[#3D3A3A]">Reset Link</h2>
              <p className="text-xl text-[#9DA4AE] mb-6">
                The verification link email has been sent to your registered
                email ID. Please follow the instructions to reset your password.
              </p>
              <button
                onClick={handleBackToLogin} // Go back to the form
                className=" w-full py-2 px-4 text-white rounded-lg bg-[#3BC1CD]"
              >
                Back to login
              </button>
              
            </div>
          ) : (
            // Content to display before form submission
            <>
              <h2 className="text-[44px] font-bold mb-4 text-center">
                Forgot Password?
              </h2>
              <p className="text-xl mb-6 text-center text-[#9DA4AE]">
                Please enter your registered email address, we will send the
                verification link with the associated email address.
              </p>
              <form className="flex flex-col gap-4">
                {/* Email Input Field */}
                <TextField
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!emailError}
                  helperText={emailError}
                  placeholder="Your Email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email style={{ color: "#B0B0B0" }} />
                      </InputAdornment>
                    ),
                    style: {
                      border: "1px solid #C4C4C4",
                      borderRadius: "10px",
                      padding: "0px 14px",
                      background: "#fff", // Light gray background
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    input: {
                      color: "#333333",
                    },
                    "& .MuiFormHelperText-root": {
                      fontSize: "16px",
                    },
                  }}
                />

                {/* Submit Button */}
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={`py-2 px-4 text-white rounded-lg ${
                    email ? "bg-[#3BC1CD]" : "bg-[#DADADA]"
                  }`}
                  disabled={!email} // Optional: Disable the button if input is empty
                >
                  Submit
                </button>
              </form>
              <Link to={"/signin"} className="text-[black] mt-4 text-center">
                Back to login
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
