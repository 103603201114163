import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Popover,
    TextField,
  } from "@mui/material";
  import React, { useState } from "react";
  
  interface FilterPopoverProps {
    onFilterSubmit?: (filters: string[]) => void;
  }
  const FilterPopover: React.FC<FilterPopoverProps> = ({ onFilterSubmit }) => {
    const [filter, setFilter] = useState({
      Active: false,
      Inactive: false,
      Blocked: false,
    });
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setFilter((prev) => ({ ...prev, [name]: checked }));
    };
  
    const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseFilter = () => {
      setAnchorEl(null);
    };
  
    const handleSubmitFilter = () => {
      const selectedFilters = Object.keys(filter).filter((key) => filter[key]);
      if (onFilterSubmit) {
        onFilterSubmit(selectedFilters);
      }
  
      handleCloseFilter();
    };
    const isFilterOpen = Boolean(anchorEl);
  
    return (
      <>
        <Button
          variant="outlined"
          endIcon={
            <img
              src="/assets/filter-Icon.png"
              className="h-[20px] "
              alt="filter icon"
            />
          }
          onClick={handleOpenFilter}
          sx={{
            color: "#111",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
          }}
        >
          Filter
        </Button>
        <Popover
          open={isFilterOpen}
          anchorEl={anchorEl}
          onClose={handleCloseFilter}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <div style={{ padding: "20px", width: "350px" }}>
            <div className="flex justify-between">
              <h3 style={{ marginBottom: "10px", fontSize: "16px" }}>
                Filter By
              </h3>
              <span
                className="text-[12px] cursor-pointer"
                onClick={() =>
                  setFilter({ Active: false, Inactive: false, Blocked: false })
                }
              >
                Reset
              </span>
            </div>
  
            <h4 style={{ marginBottom: "10px", fontSize: "12px" }}>Status</h4>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Active"
                    checked={filter.Active}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Active"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="Inactive"
                    checked={filter.Inactive}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Inactive"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="Blocked"
                    checked={filter.Blocked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Blocked"
              />
            </FormGroup>
  
            <h4 style={{ marginBottom: "10px", fontSize: "14px" }}>Date Range</h4>
            <TextField
              variant="outlined"
              placeholder="From Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={{ marginBottom: "10px" }}
            />
            <TextField
              variant="outlined"
              placeholder="To Date"
              type="date"
              InputLabelProps={{ shrink: true }}

              
              fullWidth
              style={{ marginBottom: "10px" }}
            />
  
            {/* Submit Button */}
            <Button
              variant="contained"
              fullWidth
              style={{ backgroundColor: "#3BC1CD", color: "#fff" }}
              onClick={handleSubmitFilter}
            >
              Submit
            </Button>
          </div>
        </Popover>
      </>
    );
  };
  
  export default FilterPopover;
  