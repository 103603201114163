import React, { useState } from "react";
import { Button, Card, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FAQEditor from "./FAQEditor.tsx";
import TabMenu from "./TabMenu.tsx";

const initialFAQs = [
  {
    id: 1,
    question: "How Secure Is Information About Me?",
    answer:
      "We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input.",
  },
  {
    id: 2,
    question: "How Can I Update My Information?",
    answer:
      "You can update your information by logging into your account and navigating to the profile section.",
  },
  {
    id: 3,
    question: "What Payment Methods Are Accepted?",
    answer:
      "We accept various payment methods, including credit cards, debit cards, and PayPal.",
  },
  {
    id: 4,
    question: "Is My Data Shared With Third Parties?",
    answer:
      "Your data is not shared with third parties unless it is necessary to provide our services or required by law.",
  },
  {
    id: 5,
    question: "Can I Delete My Account?",
    answer:
      "Yes, you can delete your account by contacting our support team. Please note that some data may be retained for legal purposes.",
  },
];

const FAQPage: React.FC = () => {
  const [faqs, setFaqs] = useState(initialFAQs);
  const [isEditing, setIsEditing] = useState(false);
  const [currentFAQ, setCurrentFAQ] = useState<{
    id?: number;
    question: string;
    answer: string;
  } | null>(null);

  const handleAddNewFAQ = () => {
    setCurrentFAQ({ question: "", answer: "" });
    setIsEditing(true);
  };

  const handleEditFAQ = (faq: any) => {
    setCurrentFAQ(faq);
    setIsEditing(true);
  };

  const handleDeleteFAQ = (id: number) => {
    setFaqs(faqs.filter((faq) => faq.id !== id));
  };

  const handleSaveFAQ = (faq: any) => {
    if (faq.id) {
      setFaqs(faqs.map((f) => (f.id === faq.id ? faq : f)));
    } else {
      setFaqs([...faqs, { ...faq, id: faqs.length + 1 }]);
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div className="py-6 px-9">
      <TabMenu selectedTab="/static-content/faqs" />

      {!isEditing ? (
        <div className="border rounded-lg shadow">
          <div className="flex justify-between items-center bg-[#E3F7F9] p-4 rounded-t-lg">
            <h1 className="text-lg font-bold">FAQs</h1>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#3BC1CD",
                color: "#FFF",
                padding: "8px 16px",
                borderRadius: "8px",
              }}
              onClick={handleAddNewFAQ}
            >
              Add New FAQ
            </Button>
          </div>
          {faqs.map((faq) => (
            <Card
              key={faq.id}
              style={{
                padding: "16px",
                margin: "12px",
                
                border: "1px solid #C4C4C4",
                borderRadius: "10px",
              }}
            >
              <div className="flex justify-between items-center">
                <div>
                  <strong style={{ display: "block", marginBottom: "6px" }}>
                    Q. {faq.question}
                  </strong>
                  <p style={{ margin: 0, color: "#555" }}>{faq.answer}</p>
                </div>
                <div>
                  <IconButton onClick={() => handleEditFAQ(faq)}>
                    <EditIcon style={{ color: "#3BC1CD" }} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteFAQ(faq.id)}>
                    <DeleteIcon style={{ color: "red" }} />
                  </IconButton>
                </div>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <FAQEditor
          faq={currentFAQ}
          onSave={handleSaveFAQ}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default FAQPage;
