import React, { useState } from "react";
import { TextField, FormControlLabel, Checkbox, Button } from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff, Email, Lock } from "@mui/icons-material";

import { Link, useNavigate } from "react-router-dom";

const RightPanel: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = () => {
    let isValid = true;

    if (!email.includes("@")) {
      setEmailError("Enter a valid Email ID");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (isValid) {
      alert(`Sign In Successful`);
      localStorage.setItem("token", "user-auth-token");
      navigate("/dashboard");
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="flex flex-col justify-center p-8 px-8 md:px-32 h-full">
      <h2 className="text-[44px] font-bold mb-4 text-center">Admin Sign In</h2>
      <p className="text-xl mb-6 text-center text-[#9DA4AE]">
        Sign in with your Email ID
      </p>
      <form className="flex flex-col gap-4">
        {/* Email Field */}
        <TextField
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
          placeholder="Your Email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email style={{ color: "#B0B0B0" }} /> {/* Email Icon */}
              </InputAdornment>
            ),
            style: {
              border: "1px solid #C4C4C4",
              borderRadius: "10px",
              padding: "0px 14px",
              background: "#FFF", // Light gray background
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none", // Remove default borders
              },
            },
            input: {
              color: "#333333", // Placeholder color
            },
            "& .MuiFormHelperText-root": {
              fontSize: "16px",
            },
          }}
        />

        {/* Password Field */}
        <TextField
          variant="outlined"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!passwordError}
          helperText={passwordError}
          placeholder="Your Password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock style={{ color: "#B0B0B0" }} /> {/* Lock Icon */}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handlePasswordToggle}>
                  {showPassword ? (
                    <VisibilityOff style={{ color: "#B0B0B0" }} />
                  ) : (
                    <Visibility style={{ color: "#B0B0B0" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              border: "1px solid #C4C4C4",
              borderRadius: "10px",
              padding: "0px 14px",
              background: "#fff", // Light gray background
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none", // Remove default borders
              },
            },
            input: {
              color: "#333333", // Placeholder color
            },
            "& .MuiFormHelperText-root": {
              fontSize: "16px",
            },
          }}
        />

        {/* Remember Me Checkbox */}
        <FormControlLabel
          control={<Checkbox sx={{ color: "#B0B0B0" }} />}
          label={<span style={{ color: "#B0B0B0" }}>Remember me</span>}
        />

        {/* Sign In Button */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#3BC1CD",
            padding: "12px 0px",
            fontSize: "16px",
          }}
          color="primary"
          onClick={handleSignIn}
        >
          Sign In
        </Button>
      </form>
      <Link
        to="/forgot-password"
        className="text-black mt-4 text-center text-base"
      >
        Forgot Password?
      </Link>
    </div>
  );
};

export default RightPanel;
