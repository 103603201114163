import React from "react";
import { Tabs, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";

const TabMenu: React.FC<{ selectedTab: string }> = ({ selectedTab }) => {
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      TabIndicatorProps={{
        style: { display: "none" }, // Hide the default indicator
      }}
      sx={{gap:"12px"}}
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
      }}
    >
      {[
        { label: "Privacy Policy", value: "/static-content/privacy-policy" },
        { label: "FAQs", value: "/static-content/faqs" },
        { label: "About Us", value: "/static-content/about-us" },
        { label: "Terms and Conditions", value: "/static-content/terms" },
        { label: "Contact Us", value: "/static-content/contact-us" },
      ].map((tab) => (
        <Tab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          style={{
            backgroundColor:
              selectedTab === tab.value ? "#3BC1CD" : "#FFF", // Active tab background
            color: selectedTab === tab.value ? "#FFF" : "#000", // Active tab text color
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            fontWeight: "bold",
            textTransform: "none", // Disable uppercase
            padding: "6px 16px",
            minWidth: "auto", // Prevent tabs from stretching
            marginRight:"12px"
          }}
        />
      ))}
    </Tabs>
  );
};

export default TabMenu;
