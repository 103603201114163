import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Signin from "./pages/signin/index.tsx";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword.tsx";
import ResetPassword from "./pages/resetPassword/ResetPassword.tsx";
import UserManagement from "./components/dashboard/user-management/index.tsx";
import Dashboard from "./components/dashboard/dashboardPage/DashboardPage.tsx";
import RoomManagement from "./components/dashboard/room-management/RoomManagement.tsx";
import RoomDetails from "./components/dashboard/room-management/RoomDetails.tsx";
import TestimonialManagement from "./components/dashboard/testimonial-management/TestimonialManagement.tsx";
import TestimonialDetails from "./components/dashboard/testimonial-management/TestimonialDetails.tsx";
import AddNewTestimonial from "./components/dashboard/testimonial-management/AddNewTestimonial.tsx";
import BookingManagement from "./components/dashboard/booking-management/BookingManagement.tsx";
import SubAdminManagement from "./components/dashboard/subAdmin-management/SubAdminManagement.tsx";
import ImageManagement from "./components/dashboard/image-management/ImageManagement.tsx";
import Profile from "./components/dashboard/profile/Profile.tsx";
import AddSubAdmin from "./components/dashboard/subAdmin-management/AddSubAdmin.tsx";
import Navbar from "./components/commonComponents/DashboardNavbar.tsx";
import Sidebar from "./components/commonComponents/DashboardSidebar.tsx";
import UserDetails from "./components/dashboard/user-management/UserDetails.tsx";
import BookingDetails from "./components/dashboard/booking-management/BookingDetails.tsx";
import EditUnit from "./components/dashboard/room-management/EditUnit.tsx";

// Static Content Management Components
import PrivacyPolicy from "./components/dashboard/content-management/PrivacyPolicy.tsx";
import FAQs from "./components/dashboard/content-management/FAQs.tsx";
import AboutUs from "./components/dashboard/content-management/AboutUs.tsx";
import TermsAndConditions from "./components/dashboard/content-management/TermsAndConditions.tsx";
import ContactUs from "./components/dashboard/content-management/ContactUs.tsx";

// PrivateRoute component to protect routes
const PrivateRoute = ({ element }) => {
  const isAuthenticated = Boolean(localStorage.getItem("token")); // Example: Check if user is authenticated
  return isAuthenticated ? element : <Navigate to="/signin" />;
};

// Layout for private routes
const PrivateLayout = () => {
  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar />
      {/* Content Area */}
      <div className="flex-1 ml-1/6 overflow-y-auto "
    style={{ marginLeft: "16.6667%" }} >
        {/* Navbar */}
        <Navbar title="Admin Panel" />
        {/* Page Content */}
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Outlet />,
      children: [
        {
          path: "signin",
          element: <Signin />,
        },
        {
          index: true,
          element: <Signin />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: "/",
      element: <PrivateRoute element={<PrivateLayout />} />, // Protect routes with PrivateRoute
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "user-management",
          element: <UserManagement />,
        },
        {
          path: "/user-details/:id",
          element: <UserDetails />,
        },
        {
          path: "room-management",
          element: <RoomManagement />,
        },
        {
          path: "/room-details/:id",
          element: <RoomDetails />,
        },
        {
          path: "/room-management/edit-unit",
          element: <EditUnit />,
        },
        {
          path: "testimonial-management",
          element: <TestimonialManagement />,
        },
        {
          path: "testimonial-management/add-newfeedback",
          element: <AddNewTestimonial />,
        },
        {
          path: "testimonial-management/testimonial-details/:id",
          element: <TestimonialDetails />,
        },
        {
          path: "booking-management",
          element: <BookingManagement />,
        },
        {
          path: "/booking-details/:id",
          element: <BookingDetails />,
        },
        {
          path: "subadmin-management",
          element: <SubAdminManagement />,
        },
        {
          path: "subadmin-management/add-subadmin",
          element: <AddSubAdmin />,
        },
        {
          path: "image-management",
          element: <ImageManagement />,
        },
        // Static Content Management Routes
        {
          path: "static-content/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "static-content/faqs",
          element: <FAQs />,
        },
        {
          path: "static-content/about-us",
          element: <AboutUs />,
        },
        {
          path: "static-content/terms",
          element: <TermsAndConditions />,
        },
        {
          path: "static-content/contact-us",
          element: <ContactUs />,
        },
        // Add more private routes here
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
