import React, { useState } from "react";
import Navbar from "../signin/Navbar.tsx";
import LeftPanel from "../signin/LeftPanel.tsx";
import { InputAdornment, IconButton, TextField, Button } from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";

const ForgotPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");

  const handleResetPassword = () => {
    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setError("");
    alert("Password reset successfully!");
  };

  return (
    <div className="h-screen flex flex-col">
      {/* Reuse Navbar */}
      <Navbar />

      <div className="flex flex-1">
        {/* Reuse LeftPanel */}
        <div className="w-1/2">
          <LeftPanel />
        </div>

        {/* Right Panel */}
        <div className="w-1/2 flex flex-col justify-center p-8 px-8 md:px-32">
          <>
            <h2 className="mb-14 text-[44px] text-[#3D3A3A] font-bold text-center">
              Reset Your Password
            </h2>

            <form className="flex flex-col gap-4">
              <TextField
                variant="outlined"
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter New Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock style={{ color: "#B0B0B0" }} /> {/* Lock Icon */}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    border: "1px solid #C4C4C4",
                    borderRadius: "10px",
                    padding: "0px 14px",
                    background: "#fff", // Light gray background
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", // Remove default borders
                    },
                  },
                  input: {
                    color: "#333333", // Placeholder color
                  },
                }}
              />

              {/* Confirm Password Field */}

              <TextField
                variant="outlined"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm New Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock style={{ color: "#B0B0B0" }} /> {/* Lock Icon */}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    border: "1px solid #C4C4C4",
                    borderRadius: "10px",
                    padding: "0px 14px",
                    background: "#fff", // Light gray background
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", // Remove default borders
                    },
                  },
                  input: {
                    color: "#333333", // Placeholder color
                  },
                }}
              />

              {/* Error Message */}
              {error && <p className="text-red-500 text-sm">{error}</p>}

              {/* Reset Button */}
              <Button
                variant="contained"
                sx={{ backgroundColor: "#3BC1CD" ,
                  fontSize:"16px",
                  padding:"12px 0px",
                  marginTop:"36px"
                }}
                fullWidth
                onClick={handleResetPassword}
              >
                Reset Password
              </Button>
            </form>
          </>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
