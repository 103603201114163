import React, { useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const properties = [
  "Aria Vacation Club",
  "Luxury Stays",
  "City Views",
  "Ocean Paradise",
];

const EditUnit = () => {
  const [unitName, setUnitName] = useState<string>("");
  const [property, setProperty] = useState<string>("Aria Vacation Club");
  const [description, setDescription] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);
  const [showModal, setShowModal] = useState(false);

  // Handle image upload
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setImages([...images, ...Array.from(event.target.files)]);
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    // console.log("Updated Unit Details", {
    //   unitName,
    //   property,
    //   description,
    //   images,
    // });
    // alert("Unit updated successfully!");
    setShowModal(true);
  };

  // Handle form cancel
  const handleCancel = () => {
    console.log("Edit Cancelled");
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="py-6 px-9 w-[70%]">
      <h1 className="text-2xl font-bold mb-6">Edit New Unit</h1>
      <form className="flex flex-col gap-6">
        <div className="flex gap-9">
          {/* Unit Name */}
          <div className="w-full">
            <label className="block text-[#979797] text-sm font-normal mb-2">
              Unit Name
            </label>
            <TextField
              placeholder="Enter Unit Name"
              value={unitName}
              onChange={(e) => setUnitName(e.target.value)}
              className="w-full"
              style={{
                border: "1px solid #C4C4C4",
                borderRadius: "10px",
                padding: "0px 14px",
                background: "#fff",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
                input: {
                  color: "#333333",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "16px",
                },
              }}
            />
          </div>

          {/* Associated Property */}
          <div className="w-full">
            <label className="block text-[#979797] text-sm font-normal mb-2">
              Associated Property
            </label>
            <TextField
              select
              value={property}
              onChange={(e) => setProperty(e.target.value)}
              className="w-full"
              style={{
                border: "1px solid #C4C4C4",
                borderRadius: "10px",
                padding: "0px 14px",
                background: "#fff",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
                input: {
                  color: "#333333",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "16px",
                },
              }}
            >
              {properties.map((prop, index) => (
                <MenuItem key={index} value={prop}>
                  {prop}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        {/* Description */}
        <div>
          <label className="block text-[#979797] text-sm font-normal mb-2">
            Description
          </label>
          <TextField
            placeholder="Enter description here"
            multiline
            rows={4}
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{
              height: "187px",
              border: "1px solid #C4C4C4",
              borderRadius: "10px",
              padding: "0px 14px",
              background: "#fff",
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              input: {
                color: "#333333",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "16px",
              },
            }}
          />
        </div>

        {/* Image Upload */}
        <div>
          <label className="block text-[#979797] text-sm font-normal mb-2">
            Add Image
          </label>
          <div className="flex items-center justify-center gap-4 h-[187px]">
            {/* Image Preview */}
            <div className="border rounded-lg w-[50%] h-[100%]">
              {true ? (
                <img
                  src={"/assets/room-Demo.png"}
                  alt="Preview"
                  className=" w-full h-[100%] object-cover"
                />
              ) : (
                <div className="w-32 h-32 bg-gray-200 flex items-center justify-center text-gray-500">
                  No Image
                </div>
              )}
            </div>

            {/* Upload Area */}
            <div className="border border-dashed rounded-lg p-4 bg-[#EAF7FA] flex flex-col items-center justify-center w-[50%] h-[100%]">
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="upload-images"
                onChange={handleImageUpload}
              />
              <label
                htmlFor="upload-images"
                className="cursor-pointer flex flex-col items-center"
              >
                <UploadFileIcon style={{ fontSize: 40, color: "#3BC1CD" }} />
                <p className="text-gray-500">Upload Image</p>
              </label>
            </div>
          </div>
          <p className="text-sm text-gray-500 mt-2">
            Maximum size allowed: 5 MB, Format supported: JPEG, PNG, JPG only
          </p>
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4">
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #C4C4C4",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "700",
              padding: "12px 55px",
              color: "#3D3A3A",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3BC1CD",
              fontSize: "16px",
              fontWeight: "700",
              padding: "12px 55px",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </form>

      <Modal
        open={showModal}
        onClose={handleModalCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
            display:"flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            overflow: "hidden",
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "32px", color: "#3D3A3A", fontWeight: "700" }}
          >
            Unit Added
          </Typography>
          <div className="">
            <img src="/assets/tickIcon.png" alt="tick icon" />
          </div>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              fontSize: "20px",
              color: "#3D3A3A",
              textAlign: "center",
            }}
          >
            Congratulations!
            <br />
            The Unit has been updated successfully
          </Typography>
          <Box
            sx={{
                width:"100%",
              mt: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleModalCancel}
              sx={{
                fontSize:"16px",
                padding:'12px',
                width: "100%",
                backgroundColor: "#3BC1CD",
                borderRadius: "8px",
                color: "#fff",
                border: "none",
              }}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EditUnit;
