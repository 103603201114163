import React from "react";
import { Box, Typography, Grid, Paper, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";

const RoomDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "locationnnnn");

  const bookingDetails = location?.state?.user;

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box sx={{ padding: "20px", margin: "0 auto" }}>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: "20px" }}>
        <Button
          variant="text"
          onClick={handleGoBack}
          sx={{ fontWeight: "normal" }}
        >
          <ArrowBackIosIcon />
        </Button>
        Room Details
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {/* Property Image */}
            <Box
              component="img"
              src={"/assets/demo-booking-property.png"}
              alt={bookingDetails.propertyName}
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
              }}
            />
            {/* Property Name */}
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "700", color: "#3D3A3A" }}
            >
              One Bedroom Residence
            </Typography>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "400", color: "#979797" }}
            >
              Property
            </Typography>
            {/* Property About */}
            <Typography
              variant="body2"
              sx={{ fontWeight: "400", fontSize: "16px", color: "#3D3A3A" }}
            >
              Where the Arabian Desert meets the Arabian Sea there is a place
              which provides peace and private pleasure within the dynamic city
              that is Dubai. A quintessentially Middle Eastern resort, Balqis
              Residence is located on Palm Jumeirah, Dubai’s most recognized
              beachfront community.{" "}
            </Typography>
          </div>
        </Grid>

        {/* Right Section: Booking Information */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
              padding: "20px",
              height: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: "20px", fontSize: "24px", fontWeight: "400" }}
            >
              Unit Details{" "}
            </Typography>

            <div className="flex flex-col ">
              <div>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Unit Name
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  {bookingDetails.unitName}
                </Typography>
              </div>

              <div>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Added On{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  11 Nov 2024{" "}
                </Typography>
              </div>

              <div>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Associated With{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  Aria Vacation Club{" "}
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoomDetails;
