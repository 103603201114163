import React, { useState } from "react";
import { DataTable } from "../../dataTable/index.ts";
import { Switch, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import FilterPopover from "./FilterPopover.tsx";
import { GridColDef } from "@mui/x-data-grid";

const rows = [
  {
    id: 1,
    title: "Feature Enhancement",
    description: "Add a filter to the search functionality.",
    userName: "John Doe",
    addedOn: "Apr 21, 2022",
    status: "Active",
  },
  {
    id: 2,
    title: "Bug Fix",
    description: "Resolve the issue with user login persistence.",
    userName: "Jane Smith",
    addedOn: "May 10, 2022",
    status: "Inactive",
  },
  {
    id: 3,
    title: "UI Update",
    description: "Redesign the dashboard for better user experience.",
    userName: "Chris Brown",
    addedOn: "Jun 15, 2022",
    status: "Active",
  },
  {
    id: 4,
    title: "API Integration",
    description: "Integrate the payment gateway with the backend.",
    userName: "Emily Davis",
    addedOn: "Jul 01, 2022",
    status: "Inactive",
  },
  {
    id: 5,
    title: "Security Improvement",
    description: "Implement 2-factor authentication for login.",
    userName: "Michael Johnson",
    addedOn: "Aug 12, 2022",
    status: "Active",
  },
  {
    id: 6,
    title: "Performance Optimization",
    description: "Optimize database queries for faster load times.",
    userName: "Sarah Lee",
    addedOn: "Sep 05, 2022",
    status: "Active",
  },
  {
    id: 7,
    title: "New Feature",
    description: "Introduce dark mode for the application.",
    userName: "David Wilson",
    addedOn: "Oct 20, 2022",
    status: "Inactive",
  },
  {
    id: 8,
    title: "Accessibility Improvements",
    description: "Add ARIA labels for better screen reader support.",
    userName: "Sophia Martinez",
    addedOn: "Nov 10, 2022",
    status: "Active",
  },
  {
    id: 9,
    title: "Content Update",
    description: "Update the FAQ section with recent changes.",
    userName: "James Anderson",
    addedOn: "Dec 02, 2022",
    status: "Active",
  },
  {
    id: 10,
    title: "Database Migration",
    description: "Migrate the database to a cloud provider.",
    userName: "Olivia Thomas",
    addedOn: "Jan 15, 2023",
    status: "Inactive",
  },
];

const TestimonialManagement = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    console.log("Edit", selectedRow);
    handleMenuClose();
  };

  const handleDelete = (event: any) => {
    event.stopPropagation();
    // handleMenuOpen(event, params.row)
    console.log("Delete", selectedRow);
    handleMenuClose();
  };

  const handleToggleChange = (row: any) => {
    console.log("Toggle Switch Clicked for:", row);
    setRows((prevRows) =>
      prevRows.map((r) =>
        r.id === row.id
          ? { ...r, status: r.status === "Active" ? "Inactive" : "Active" }
          : r
      )
    );
  };
  // const handleDelete = (e) => {
  //   e.stopProgagation()
  //   handleMenuOpen(e, params.row)

  // };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "S.no",
      flex: 0.5, // Dynamically adjust width (smaller)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "userName",
      headerName: "User Name",
      flex: 1.5, // Dynamically adjust width (larger)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "addedOn",
      headerName: "Added on",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1, // Dynamically adjust width (medium)
      renderCell: (params) => (
        <span
          style={{
            borderRadius: "24px",
            padding: "4px 8px",
            backgroundColor: params.value === "Active" ? "#52F0BE" : "#F0F0F0",
            color: params.value === "Active" ? "#0B6067" : "#9DA4AE",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
      headerClassName: "column-header-bg-green",
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      headerClassName: "column-header-bg-green",

      renderCell: (params) => (
        <div className="flex items-center gap-2">
          {/* Toggle Switch */}
          <Switch
            checked={params.row.status === "Active"}
            onChange={() => handleToggleChange(params.row)}
            color="primary"
          />
          {/* Three-dot Button */}
          <div
            onClick={handleDelete}
            className="p-2 rounded-lg cursor-pointer"
            style={{ border: "1px solid #C4C4C4" }}
          >
            <img src="/assets/delete-Icon.png" alt="deleteIcon" />{" "}
          </div>
        </div>
      ),
    },
  ];

  const handleRowClick = (params) => {
    console.log(params, "paraaa");
    setSelectedUser(params);
    navigate(`/testimonial-management/testimonial-details/${params.id}`, {
      state: { user: params },
    });
  };

  return (
    <div className="py-6 px-9">
      <DataTable
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        filterComponent={
          <FilterPopover
            anchorEl={anchorEl}
            onOpen={(event) => setAnchorEl(event.currentTarget)}
            onClose={() => setAnchorEl(null)}
            setAnchorEl={setAnchorEl}
          />
        }
        searchPlaceholder="Search by Name or Email"
        filterable
        customAction={
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              backgroundColor: "#3BC1CD",
              width: "200px",
              textTransform: "none",
            }}
            onClick={() => navigate("/testimonial-management/add-newFeedback")}
          >
            Add New Feedback
          </Button>
        }
      />
    </div>
  );
};

export default TestimonialManagement;
