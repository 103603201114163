import React from 'react';

const LeftPanel: React.FC = () => {
  return (
    <div className="left-panel-bg flex flex-col items-center justify-center">

      
      <div className=" flex items-center justify-center">
      <img src="/assets/Aria-whiteLogo.png" alt="" />
      </div>
    </div>
  );
};

export default LeftPanel;
