import React, { useState } from "react";
import { DataTable } from "../../dataTable/index.ts";
import {
  Switch,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

// import FilterPopover from "./filterPopover.tsx";
import { GridColDef } from "@mui/x-data-grid";

const rows = [
  {
    id: 1,
    unitName: "1 Studio Apartment",
    property: "Aria Vacation Club",
    joinedOn: "Apr 21, 2022",
  },
  {
    id: 2,
    unitName: "1 Bedroom Suite",
    property: "Aria Vacation Club",
    joinedOn: "Apr 21, 2022",
  },
  {
    id: 3,
    unitName: "1 Studio Apartment",
    property: "Aria Vacation Club",
    joinedOn: "Apr 21, 2022",
  },
  {
    id: 4,
    unitName: "1 Studio Apartment",
    property: "Aria Vacation Club",
    joinedOn: "Apr 21, 2022",
  },
  {
    id: 5,
    unitName: "1 Bedroom Suite",
    property: "Aria Vacation Club",
    joinedOn: "Apr 21, 2022",
  },
  {
    id: 6,
    unitName: "1 Studio Apartment",
    property: "Aria Vacation Club",
    joinedOn: "Apr 21, 2022",
  },
  {
    id: 7,
    unitName: "1 Studio Apartment",
    property: "Aria Vacation Club",
    joinedOn: "Apr 21, 2022",
  },
  {
    id: 8,
    unitName: "1 Studio Apartment",
    property: "Aria Vacation Club",
    joinedOn: "Apr 21, 2022",
  },
  {
    id: 9,
    unitName: "1 Studio Apartment",
    property: "Aria Vacation Club",
    joinedOn: "Apr 21, 2022",
  },
  {
    id: 10,
    unitName: "1 Bedroom Suite",
    property: "Aria Vacation Club",
    joinedOn: "Apr 21, 2022",
  },
];

const SubAdminManagement = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = (e:any) => {
    e.stopPropagation()
    navigate("/room-management/edit-unit");
  };

  const handleDelete = () => {
    console.log("Delete", selectedRow);
    handleMenuClose();
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "S.no",

      headerClassName: "column-header-bg-green",
    },
    {
      field: "unitName",
      headerName: "Unit Name",
      flex: 0.5,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "property",
      headerName: "property",
      flex: 0.5,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "joinedOn",
      headerName: "Joined On",
      flex: 1,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.5,
      headerClassName: "column-header-bg-green",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className=" gap-4">
          <Button
            variant="contained"
            style={{
              marginLeft: 16,
              backgroundColor: "#3BC1CD",
              color: "white",
              fontWeight: "400",
              fontSize: "16px",
            }}
            tabIndex={params.hasFocus ? 0 : -1}
            onClick={handleEdit}
          >
            <EditIcon />
            Edit
          </Button>
        </div>
      ),
    },
  ];

  // const handleRowClick = (params) => {
  //   console.log(params, "paraaa");
  //   setSelectedUser(params);
  // };
  const handleRowClick = (params) => {
    setSelectedUser(params);
    console.log(selectedUser, "selectedUserselectedUser");
    navigate(`/room-details/${params.id}`, { state: { user: params } });
  };

  return (
    <div className="py-6 px-9">
      <DataTable
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        searchPlaceholder="Search by Name or Email"
        filterable
      />
    </div>
  );
};

export default SubAdminManagement;
