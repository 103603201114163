import React, { useState } from "react";
import TabMenu from "./TabMenu.tsx";
import RichTextEditor from "./RichTextEditor.tsx";
import { Button } from "@mui/material";

const PrivacyPolicy: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [editorValue, setEditorValue] = useState<string>('');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    // Here you would typically save the content to your backend
    // For now, we'll just exit edit mode
    setIsEditing(false);
  };

  const handleEditorChange = (e: { target: { value: string } }) => {
    // Directly set the HTML content from the editor
    setEditorValue(e.target.value);
  };

  return (
    <div className="py-6 px-9">
      <TabMenu selectedTab="/static-content/privacy-policy" />
      <div className="mt-6 border rounded-lg shadow h-[537px]">
        <div className="flex justify-between items-center bg-[#E3F7F9] p-4 rounded-t-lg">
          <h2 className="font-bold text-lg">Privacy Policy</h2>
          {!isEditing && (
            <Button
              variant="contained"
              style={{ backgroundColor: "#3BC1CD", color: "#FFF" }}
              onClick={handleEditClick}
            >
              Edit
            </Button>
          )}
        </div>
        <div className="p-4">
          {isEditing ? (
            <>
              <RichTextEditor
                name="privacyPolicy"
                value={editorValue}
                onChange={handleEditorChange}
                placeholder="Enter privacy policy details"
              />

              <button
                onClick={handleSave}
                style={{
                  marginTop: "10px",
                  padding: "8px 16px",
                  background: "#3BC1CD",
                  color: "#FFF",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Save
              </button>
            </>
          ) : (
            <div
              className="text-gray-600"
              dangerouslySetInnerHTML={{ __html: editorValue }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
