import React from "react";
import { DataTable } from "../../dataTable/index.ts"; // Correct import
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const BookingManagement = () => {
  const navigate = useNavigate();
  const initialRows = [
    {
      id: 1,
      unitName: "1 Studio Apartment",
      property: "Aria Vacation Club",
      userId: "John1234",
      phone: "9990074589",
      guests: "2 Adults and 1 child",
      status: "Accepted",
      remarks: "Need extra pillows in the room",
    },
    {
      id: 2,
      unitName: "1 Bedroom Suite",
      property: "Aria Vacation Club",
      userId: "Sam1234",
      phone: "9990074589",
      guests: "2 Adults and 1 child",
      status: "Rejected",
      remarks: "Need extra pillows in the room",
    },
    {
      id: 3,
      unitName: "1 Studio Apartment",
      property: "Aria Vacation Club",
      userId: "Chris1234",
      phone: "9990074589",
      guests: "2 Adults and 1 child",
      status: "Accepted",
      remarks: "Need extra pillows in the room",
    },
    {
      id: 4,
      unitName: "1 Studio Apartment",
      property: "Aria Vacation Club",
      userId: "Shawn1234",
      phone: "9990074589",
      guests: "2 Adults and 1 child",
      status: "Rejected",
      remarks: "Need extra pillows in the room",
    },
    {
      id: 5,
      unitName: "1 Bedroom Suite",
      property: "Aria Vacation Club",
      userId: "Charlie1234",
      phone: "9990074589",
      guests: "2 Adults and 1 child",
      status: "Accepted",
      remarks: "Need extra pillows in the room",
    },
    {
      id: 6,
      unitName: "1 Studio Apartment",
      property: "Aria Vacation Club",
      userId: "John1234",
      phone: "9990074589",
      guests: "2 Adults and 1 child",
      status: "Accepted",
      remarks: "Need extra pillows in the room",
    },
    {
      id: 7,
      unitName: "1 Studio Apartment",
      property: "Aria Vacation Club",
      userId: "Sam1234",
      phone: "9990074589",
      guests: "2 Adults and 1 child",
      status: "Accepted",
      remarks: "Need extra pillows in the room",
    },
    {
      id: 8,
      unitName: "1 Studio Apartment",
      property: "Aria Vacation Club",
      userId: "Chris1234",
      phone: "9990074589",
      guests: "2 Adults and 1 child",
      status: "Accepted",
      remarks: "Need extra pillows in the room",
    },
    {
      id: 9,
      unitName: "1 Studio Apartment",
      property: "Aria Vacation Club",
      userId: "Shawn1234",
      phone: "9990074589",
      guests: "2 Adults and 1 child",
      status: "Rejected",
      remarks: "Need extra pillows in the room",
    },
    {
      id: 10,
      unitName: "1 Bedroom Suite",
      property: "Aria Vacation Club",
      userId: "Charlie1234",
      phone: "9990074589",
      guests: "2 Adults and 1 child",
      status: "Rejected",
      remarks: "Need extra pillows in the room",
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "S.no",
      flex: 0.5, // Dynamically adjust width (smaller)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "unitName",
      headerName: "Unit Name",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "property",
      headerName: "Property",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "userId",
      headerName: "User ID",
      flex: 1, // Dynamically adjust width (larger)
      headerClassName: "column-header-bg-green",
    },

    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "guests",
      headerName: "Total Guests",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1, // Dynamically adjust width (medium)
      renderCell: (params) => (
        <span
          style={{
            borderRadius: "24px",
            padding: "4px 8px",
            backgroundColor: "rgba(169, 237, 243, 0.3)",
            color: params.value === "Unblocked" ? "#00C3A3" : "#FF6B6B",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
      headerClassName: "column-header-bg-green",
    },
    {
      field: "remarks",
      headerName: "Remarks",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <DataTable
        rows={initialRows}
        columns={columns}
        filterable
        onRowClick={(row) =>
          navigate(`/booking-details/${row.id}`, { state: { user: row } })
        }
      />
    </div>
  );
};

export default BookingManagement;
