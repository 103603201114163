import {
  Button,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";

interface FilterPopoverProps {
  anchorEl: HTMLElement | null;
  onOpen: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}
const FilterPopover: React.FC<FilterPopoverProps> = ({ anchorEl, onClose }) => {
  const [filter, setFilter] = React.useState<string>("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleReset = () => {
    setFilter("");
  };

  const isFilterOpen = Boolean(anchorEl);

  return (
    <div>
      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "20px", width: "350px" }}>
          <div className="flex justify-between">
            <h3 style={{ marginBottom: "10px", fontSize: "16px" }}>
              Filter By
            </h3>
            <span
              className="text-[12px] cursor-pointer"
              onClick={() => setFilter("")}
            >
              Reset
            </span>
          </div>

          <h4 style={{ marginBottom: "10px", fontSize: "12px" }}>Status</h4>
          <RadioGroup
            value={filter}
            onChange={handleFilterChange}
            sx={{ marginBottom: "16px" }}
          >
            <FormControlLabel
              sx={{
                border: "1px solid #C4C4C4",
                margin: "0px",
                borderRadius: "8px",
                marginBottom: "12px",
              }}
              value="Inactive"
              control={<Radio />}
              label="Inactive"
            />
            <FormControlLabel
              sx={{
                border: "1px solid #C4C4C4",
                margin: "0px",
                borderRadius: "8px",
              }}
              value="Active"
              control={<Radio />}
              label="Active"
            />
          </RadioGroup>

          <h4 style={{ marginBottom: "10px", fontSize: "14px" }}>Date Range</h4>
          <TextField
            variant="outlined"
            placeholder="From Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            style={{ marginBottom: "10px" }}
          />
          <TextField
            variant="outlined"
            placeholder="To Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            style={{ marginBottom: "10px" }}
          />

          {/* Submit Button */}
          <Button
            variant="contained"
            fullWidth
            style={{ backgroundColor: "#3BC1CD", color: "#fff" }}
          >
            Submit
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default FilterPopover;
