import React from "react";
import { TextField, TextareaAutosize, TextFieldProps } from "@mui/material";

// Define a new type by extending TextFieldProps with custom properties
type CustomTextFieldProps = TextFieldProps & {
  backgroundd?: string; // Optional prop for background color
};

const CustomTextField: React.FC<CustomTextFieldProps> = ({ backgroundd, ...props }) => {
  return (
    <TextField
      {...props}
      variant="outlined"
      style={{
        border: "1px solid #C4C4C4",
        borderRadius: "10px",
        padding: "0px 14px",
        background: backgroundd || "#FFF", // Use provided color or default to #FFF
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none", // Remove default borders
          },
        },
        input: {
          color: "#333333", // Placeholder color
        },
        "& .MuiFormHelperText-root": {
          fontSize: "16px",
        },
      }}
    />
  );
};

export const CustomTextarea: React.FC<{
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  backgroundd?: string; // Optional prop for background color
}> = ({ value, onChange, placeholder, backgroundd }) => {
  return (
    <TextareaAutosize
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{
        width: "100%",
        padding: "10px",
        borderRadius: "10px",
        border: "1px solid #C4C4C4",
        fontSize: "16px",
        background: backgroundd || "#FFF", // Use provided color or default to #FFF
        color: "#333333",
        height: "200px",
      }}
    />
  );
};

export default CustomTextField;
