import React from "react";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import { TextField, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

interface DataTableProps {
  rows: any[];
  columns: GridColDef[];
  searchPlaceholder?: string;
  filterable?: boolean;
  pageSizeOptions?: number[];
  onRowClick?: (row: any) => void;
  filterComponent?: React.ReactNode;
  customAction?: React.ReactNode;
}

const DataTable: React.FC<DataTableProps> = ({
  rows,
  columns,
  searchPlaceholder = "Search...",
  filterable = false,
  pageSizeOptions = [5, 10, 25],
  filterComponent,
  onRowClick,
  customAction,
}) => {
  const [search, setSearch] = React.useState<string>("");
  const [filter, setFilter] = React.useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null); // For Popover
  const navigate = useNavigate();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const filteredRows = rows.filter((row) => {
    const matchesSearch = Object.values(row)
      .join(" ")
      .toLowerCase()
      .includes(search.toLowerCase());
    return matchesSearch;
  });
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    if (onRowClick) onRowClick(params.row);
  };

  return (
    <div style={{ height: 600}}>
      <div className="flex mb-4 justify-between items-center">
        <div className="flex w-full">
          {/* Search Input */}
          <TextField
            variant="outlined"
            placeholder={searchPlaceholder}
            value={search}
            onChange={handleSearchChange}
            sx={{ width:"450px"}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ paddingRight: "10px" }}
          />

          {filterable && filterComponent}
        </div>
        {customAction && <>{customAction}</>}
      </div>

      {/* DataGrid */}
      <DataGrid
        rows={filteredRows}
        columns={columns}
        onRowClick={handleRowClick}
        initialState={{
          pagination: {
            paginationModel: { pageSize: pageSizeOptions[0] },
          },
        }}
        pageSizeOptions={pageSizeOptions}
        sx={{
          borderRadius: "14px",
          overflow: "hidden",
          "& .MuiDataGrid-columnHeaders": {
            borderTopLeftRadius: "14px",
            borderTopRightRadius: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            borderBottomLeftRadius: "14px",
            borderBottomRightRadius: "14px",
          },
        }}
        disableColumnMenu={true}
        disableColumnSorting={true}
      />
    </div>
  );
};

export default DataTable;
