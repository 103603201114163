import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar: React.FC = () => {
  const location = useLocation();

  const links = [
    { name: "Dashboard", path: "/dashboard", icon: "dashboard-Icon.png" },
    {
      name: "User Management",
      path: "/user-management",
      icon: "userManagement-Icon.png",
    },
    { name: "Rooms", path: "/room-management", icon: "rooms-Icon.png" },
    {
      name: "Testimonials",
      path: "/testimonial-management",
      icon: "testimonials-Icon.png",
    },
    {
      name: "Booking Request",
      path: "/booking-management",
      icon: "bookings-Icon.png",
    },
    {
      name: "Static Content",
      path: "/static-content/privacy-policy",
      icon: "content-Icon.png",
    },
    {
      name: "Sub Admin",
      path: "/subadmin-management",
      icon: "subAdmin-Icon.png",
    },
    {
      name: "Static Images",
      path: "/image-management",
      icon: "image-management-icon.png",
    },
  ];

  return (
    <aside className="fixed top-0 left-0 h-full w-1/6 shadow-md px-4 overflow-y-auto">

      <div className="p-6 text-lg font-bold text-center mb-14">
        <img src="/assets/Aria-Logo.png" alt="Aria Logo" />
      </div>
      <nav className="flex flex-col justify-between h-fit ">
        <div className="mb-36">
          {links.map((link) => (
            <Link
              key={link.name}
              to={link.path}
              className={`p-4 rounded-lg  mb-2 text-base flex ${
                location.pathname === link.path
                  ? "bg-[#a9edf35c] border-[#A9EDF3] border-l-4 font-bold"
                  : "hover:bg-gray-100"
              }`}
            >
              <img
                src={`/assets/${link.icon}`}
                className="px-3"
                alt={`${link.name} icon`}
              />
              {link.name}
            </Link>
          ))}
        </div>
        <div className="flex text-[#FF0000] text-lg p-4">
          <img src="/assets/logout-Icon.png" alt="logout icon" />
          Logout
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
