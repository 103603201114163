import React, { useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  TextareaAutosize,
  Rating,
} from "@mui/material";

import CustomTextField, {
  CustomTextarea,
} from "../../commonComponents/CustomTextfield.tsx"; // Import reusable components

const AddNewTestimonial = () => {
  const [title, setTitle] = useState("");
  const [userName, setUserName] = useState("");
  const [property, setProperty] = useState("");
  const [source, setSource] = useState("");
  const [rating, setRating] = useState<number | null>(3);
  const [description, setDescription] = useState("");

  const users = ["John Smith, J123456", "Jane Doe, J987654"];
  const properties = ["Aria Vacation Club", "Beach Resort"];
  const sources = ["Instagram", "Facebook", "Google"];

  const handleSubmit = () => {
    // Handle form submission
    console.log({
      title,
      userName,
      property,
      source,
      rating,
      description,
    });
  };

  const handleCancel = () => {
    // Handle cancel action
    console.log("Cancelled");
  };

  return (
    <div className="py-6 px-9 w-[70%]">
      <Typography
        variant="h5"
        sx={{ marginBottom: "20px", fontWeight: "bold" }}
      >
        Add New Feedback
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "20px",
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Title
          </Typography>
          <CustomTextField
            fullWidth
            placeholder="Enter Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>

        {/* User Name Field */}
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            User Name
          </Typography>
          <CustomTextField
            fullWidth
            select
            label="User Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          >
            {users.map((user, index) => (
              <MenuItem key={index} value={user}>
                {user}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>

        {/* Associated Property Field */}
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Associated Property
          </Typography>
          <CustomTextField
            fullWidth
            select
            label="Associated Property"
            value={property}
            onChange={(e) => setProperty(e.target.value)}
          >
            {properties.map((property, index) => (
              <MenuItem key={index} value={property}>
                {property}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>

        {/* Source Field */}
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Source
          </Typography>
          <CustomTextField
            fullWidth
            select
            label="Source"
            value={source}
            onChange={(e) => setSource(e.target.value)}
          >
            {sources.map((source, index) => (
              <MenuItem key={index} value={source}>
                {source}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>

        {/* Rating Field */}
        <Box sx={{ gridColumn: "span 2" }}>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            What would you rate us?
          </Typography>
          <Rating
            size="large"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </Box>

        {/* Description Field */}
        <Box sx={{ gridColumn: "span 2" }}>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Description
          </Typography>
          <CustomTextarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description"
          />
        </Box>
      </Box>

      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
          gap: "10px",
        }}
      >
        <Button
            variant="outlined"
            sx={{
              border: "1px solid #C4C4C4",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "700",
              padding: "12px 55px",
              color: "#3D3A3A",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3BC1CD",
              fontSize: "16px",
              fontWeight: "700",
              padding: "12px 55px",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        
      </Box>
    </div>
  );
};

export default AddNewTestimonial;
