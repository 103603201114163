import React, { useState } from "react";
import { Button } from "@mui/material";
import RichTextEditor from "./RichTextEditor.tsx"; // Use the common RichTextEditor component

const FAQEditor: React.FC<{
  faq: { id?: number; question: string; answer: string } | null;
  onSave: (faq: any) => void;
  onCancel: () => void;
}> = ({ faq, onSave, onCancel }) => {
  const [question, setQuestion] = useState(faq?.question || "");
  const [answer, setAnswer] = useState(faq?.answer || "");

  const handleSave = () => {
    onSave({ ...faq, question, answer });
  };

  return (
    <div className="p-4">
      <h1 className="text-lg font-bold mb-4">
        {faq?.id ? "Edit FAQ" : "Add New FAQ"}
      </h1>
      <div className="mb-4">
        <label className="block mb-2 font-bold">Add Question</label>
        <RichTextEditor value={question} onChange={setQuestion} />
      </div>
      <div className="mb-4">
        <label className="block mb-2 font-bold">Add Answer</label>
        <RichTextEditor value={answer} onChange={setAnswer} />
      </div>
      <div className="flex justify-between">
        <Button
          variant="outlined"
          onClick={onCancel}
          style={{
            color: "#111",
            border: "1px solid #C4C4C4",
            borderRadius: "10px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          style={{
            backgroundColor: "#3BC1CD",
            color: "#FFF",
            borderRadius: "10px",
          }}
        >
          {faq?.id ? "Update" : "Publish"}
        </Button>
      </div>
    </div>
  );
};

export default FAQEditor;
