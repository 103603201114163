import React, { useState } from "react";
import { DataTable } from "../../dataTable/index.ts"; // Import the reusable DataTable
import FilterDropdown from "./FilterDropdown.tsx"; // Import the filter dropdown
import ConfirmationModal from "./ConfirmationModal.tsx"; // Import the confirmation modal
import { GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const initialRows = [
  {
    id: 1,
    name: "Johnattan Green",
    nameId: "John1234",
    email: "Johnattan.Green@gmail.com",
    nationality: "Indian",
    phone: "9990074589",
    joined: "Apr 21, 2022",
    status: "Active",
  },
  {
    id: 2,
    name: "Samnatha Clark",
    nameId: "Sam1234",
    email: "Samnatha.Clark@gmail.com",
    nationality: "American",
    phone: "9990074589",
    joined: "Apr 21, 2022",
    status: "Active",
  },
  {
    id: 3,
    name: "Chris Brown",
    nameId: "Chris1234",
    email: "Chris.Brown@gmail.com",
    nationality: "Indian",
    phone: "9990074589",
    joined: "Apr 21, 2022",
    status: "Active",
  },
  {
    id: 4,
    name: "Shawn Mendes",
    nameId: "Shawn1234",
    email: "Shawn.Mendes@gmail.com",
    nationality: "American",
    phone: "9990074589",
    joined: "Apr 21, 2022",
    status: "Inactive",
  },
];

const UserManagement: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [filter, setFilter] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [rows, setRows] = useState(initialRows);
  const navigate = useNavigate();

  // Handle User Click
  const handleRowClick = (params) => {
    setSelectedUser(params);
    console.log(selectedUser, "selectedUserselectedUser");
    navigate(`/user-details/${params.id}`, { state: { user: params } });
  };

  // Handle Deactivate/Activate Action
  const handleBlockUnblock = (type: string) => {
    setActionType(type);
    // setModalOpen(true);
  };

  const handleFilterSubmit = (filters: string[]) => {
    if (filters.length === 0) {
      setRows(initialRows); // Show all rows if no filter is applied
    } else {
      setRows(initialRows.filter((row) => filters.includes(row.status)));
    }
  };

  // Confirm Action in Modal
  const handleConfirm = () => {
    if (selectedUser) {
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === selectedUser.id
            ? {
                ...row,
                status: actionType === "Deactivate" ? "Inactive" : "Active",
              }
            : row
        )
      );
    }
    setModalOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "S.no",
      flex: 0.5,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "name",
      headerName: "User Name",
      flex: 1,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "nameId",
      headerName: "Name ID",
      flex: 1,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1.5,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "nationality",
      headerName: "Nationality",
      flex: 1,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "joined",
      headerName: "Joined On",
      flex: 1,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            borderRadius: "24px",
            padding: "4px 8px",
            backgroundColor: "rgba(169, 237, 243, 0.3)",
            color: params.value === "Active" ? "#00C3A3" : "#FF6B6B",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
      headerClassName: "column-header-bg-green",
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color={params.row.status === "Inactive" ? "success" : "#C4C4C4"}
          size="small"
          onClick={(e) => {
            e.stopPropagation();

            if (selectedUser) {
              setRows((prevRows) =>
                prevRows.map((row) =>
                  row.id === selectedUser.id
                    ? {
                        ...row,
                        status:
                          actionType === "Deactivate" ? "Inactive" : "Active",
                      }
                    : row
                )
              );
            }
          }}
        >
          {params.row.status === "Inactive" ? "Block" : "Unblock"}
        </Button>
      ),
      headerClassName: "column-header-bg-green",
    },
  ];

  return (
    <div className="py-6 px-9">
      <DataTable
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        searchPlaceholder="Search by Name or Email"
        filterable
        filterComponent={<FilterDropdown onFilterSubmit={handleFilterSubmit} />}
      />
      {isModalOpen && (
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={handleConfirm}
          actionType={actionType}
        />
      )}
    </div>
  );
};

export default UserManagement;
