import React, { useState } from "react";
import { Typography, Box, Switch, styled, Paper, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 50, // Width of the entire switch
  height: 24, // Height of the track
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 2,
    transform: "translateX(0)",
    "&.Mui-checked": {
      transform: "translateX(26px)", // Moves the toggle circle
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#3BC1CD", // Custom color for the active state
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 18, // Smaller toggle circle
    height: 18, // Smaller toggle circle
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 12, // Rounded track
    backgroundColor: "#E4E7EB", // Default inactive color
    opacity: 1,
    position: "relative",
  },
}));

const TestimonialDetails: React.FC = () => {
  const [status, setStatus] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleToggleStatus = () => {
    setStatus(!status);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="py-6 px-9">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="text"
          onClick={handleGoBack}
          sx={{ fontWeight: "normal" }}
        >
          <ArrowBackIosIcon />
        </Button>
        <Typography
          variant="h6"
          sx={{ fontWeight: "400", fontSize: "24px", color: "#004135" }}
        >
          Testimonial Details
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "#3D3A3A", fontSize: "16px", fontWeight: "400" }}
          >
            Mark As {status ? "Inactive" : "Active"}
          </Typography>
          <CustomSwitch
            checked={status}
            onChange={handleToggleStatus}
            color="error"
          />
        </Box>
      </Box>
      <Paper
        sx={{
          padding: "20px",
          borderRadius: "16px",
          border: "1px solid #E0E0E0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "400", color: "#3D3A3A", fontSize: "24px" }}
          >
            Excellent Stay!
          </Typography>
          <Box
            sx={{
              backgroundColor: status ? "#52F0BE" : "#F0F0F0",
              color: status ? "#0B6067" : "#9DA4AE",
              fontWeight: "700",
              borderRadius: "24px",
              padding: "8px 12px",
              fontSize: "14px",
            }}
          >
            {status ? "Active" : "Inactive"}
          </Box>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
          >
            User Name
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "400", color: "#3D3A3A", fontSize: "24px" }}
          >
            John Smith
          </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
          >
            Unit Name
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "400", color: "#3D3A3A", fontSize: "24px" }}
          >
            1 Studio Apartment
          </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
          >
            Associated With
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "400", color: "#3D3A3A", fontSize: "24px" }}
          >
            Aria Vacation Club
          </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
          >
            Added On
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "400", color: "#3D3A3A", fontSize: "24px" }}
          >
            20 Oct 2024
          </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
          >
            Description
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "400", color: "#3D3A3A", fontSize: "24px" }}
          >
            The services are great and amazing stay Where the Arabian Desert
            meets the Arabian Sea there is a place which provides peace and
            private pleasure within the dynamic city that is Dubai.
          </Typography>
        </Box>
      </Paper>
    </div>
  );
};

export default TestimonialDetails;
