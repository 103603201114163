import React from 'react';
import Navbar from './Navbar.tsx';
import LeftPanel from './LeftPanel.tsx';
import RightPanel from './RightPanel.tsx';

const App: React.FC = () => {
  return (
    <div className="h-screen flex flex-col">
      <Navbar  title={"Sigin"}/>
      <div className="flex flex-1 justify-center">
        <div className="w-1/2 hidden md:block ">
          <LeftPanel />
        </div>
        <div className=" w-full md:w-1/2 bg-white">
          <RightPanel />
        </div>
      </div>
    </div>
  );
};

export default App;
