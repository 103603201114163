import React, { useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import TitleIcon from "@mui/icons-material/Title";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CustomTextField from "../../commonComponents/CustomTextfield.tsx";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import ResetPasswordModal from "./ResetPasswordModal.tsx";

const Profile = () => {
  const [phone, setPhone] = useState("9725829212");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleResetPassword = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const handleSaveChanges = () => {
    console.log("Phone number updated to:", phone);
  };

  return (
    <div style={{ padding: "30px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          background: "#A9EDF382",
          padding: "10px 20px",
          borderRadius: "10px",
        }}
      >
        <h2 className="text-2xl font-normal text-[#004135] py-[19px]">
          Update my profile
        </h2>
        <Button
          variant="contained"
          onClick={handleResetPassword}
          style={{
            background: "#3BC1CD",
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: "400",
            textTransform: "none",
            borderRadius: "8px",
          }}
        >
          Reset Password
        </Button>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
          width: "70%",
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Full Name
          </Typography>
          <CustomTextField
            backgroundd="#E1E0E0" // Custom background color
            fullWidth
            value={"your Name"}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <PersonIcon style={{ marginRight: "10px", color: "#C4C4C4" }} />
              ),
            }}
          />
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Title{" "}
          </Typography>
          <CustomTextField
            backgroundd="#E1E0E0" // Custom background color
            fullWidth
            value={"Admin"}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <SubtitlesIcon
                  style={{ marginRight: "10px", color: "#C4C4C4" }}
                />
              ),
            }}
          />
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Email Address
          </Typography>
          <CustomTextField
            backgroundd="#E1E0E0"
            fullWidth
            value={"Admin"}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <MailIcon style={{ marginRight: "10px", color: "#C4C4C4" }} />
              ),
            }}
          />
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Phone Number{" "}
          </Typography>
          <CustomTextField
            fullWidth
            value={phone}
            onChange={handlePhoneChange}
            InputProps={{
              startAdornment: (
                <CallIcon style={{ marginRight: "10px", color: "#C4C4C4" }} />
              ),
            }}
          />
        </Box>
        <div style={{ marginTop: "30px" }}>
          <Button
            variant="contained"
            onClick={handleSaveChanges}
            style={{
              background: "#3BC1CD",
              color: "white",
              textTransform: "none",
              fontSize: "16px",
              padding: "12px 16px",
            }}
          >
            Save Changes
          </Button>
        </div>
      </div>

      {/* Include ResetPasswordModal */}
      {isModalOpen && (
        <ResetPasswordModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
};

export default Profile;
