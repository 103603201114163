import React, { useState } from "react";
import { DataTable } from "../../dataTable/index.ts";
import {
  Switch,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

import FilterPopover from "./filterPopover.tsx";
import { GridColDef } from "@mui/x-data-grid";

const rows = [
  {
    id: 1,
    name: "Johnattan Green",
    nameId: "John1234",
    email: "Johnattan.Green@gmail.com",
    role: "content Manager",
    phone: "9990074589",
    addedOn: "Apr 21, 2022",
    status: "Active",
  },
  {
    id: 2,
    name: "Samnatha Clark",
    nameId: "Sam1234",
    email: "Samnatha.Clark@gmail.com",
    role: "developer",
    phone: "9990074589",
    addedOn: "Apr 21, 2022",
    status: "Active",
  },
  {
    id: 3,
    name: "Chris Brown",
    nameId: "Chris1234",
    email: "Chris.Brown@gmail.com",
    role: "admin",
    phone: "9990074589",
    addedOn: "Apr 21, 2022",
    status: "Active",
  },
  {
    id: 4,
    name: "Shawn Mendes",
    nameId: "Shawn1234",
    email: "Shawn.Mendes@gmail.com",
    role: "user",
    phone: "9990074589",
    addedOn: "Apr 21, 2022",
    status: "Inactive",
  },
];
const SubAdminManagement = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    console.log("Edit", selectedRow);
    handleMenuClose();
  };

  const handleDelete = () => {
    console.log("Delete", selectedRow);
    handleMenuClose();
  };

  const handleToggleChange = (row: any) => {
    console.log("Toggle Switch Clicked for:", row);
    setRows((prevRows) =>
      prevRows.map((r) =>
        r.id === row.id
          ? { ...r, status: r.status === "Active" ? "Inactive" : "Active" }
          : r
      )
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "S.no",
      flex: 0.5, // Dynamically adjust width (smaller)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1.5, // Dynamically adjust width (larger)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "addedOn",
      headerName: "Added on",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1, // Dynamically adjust width (medium)
      headerClassName: "column-header-bg-green",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1, // Dynamically adjust width (medium)
      renderCell: (params) => (
        <span
          style={{
            borderRadius: "24px",
            padding: "4px 8px",
            backgroundColor: params.value === "Active" ? "#52F0BE" : "#F0F0F0",
            color: params.value === "Active" ? "#0B6067" : "#9DA4AE",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
      headerClassName: "column-header-bg-green",
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      headerClassName: "column-header-bg-green",

      renderCell: (params) => (
        <div className="flex items-center gap-4">
          {/* Toggle Switch */}
          <Switch
            checked={params.row.status === "Active"}
            onChange={() => handleToggleChange(params.row)}
            color="primary"
          />
          {/* Three-dot Button */}
          <IconButton
            onClick={(event) => handleMenuOpen(event, params.row)}
            className="focus:outline-none"
          >
            <MoreVertIcon />
          </IconButton>
          {/* Popover Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && selectedRow?.id === params.row.id}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem onClick={handleEdit}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" style={{ color: "red" }} />
              </ListItemIcon>
              <ListItemText style={{ color: "red" }}>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const handleRowClick = (params) => {
    console.log(params, "paraaa");
    setSelectedUser(params);
    // navigate(`/user-details/${params.id}`, { state: { user: params } });
  };

  return (
    <div className="py-6 px-9">

      <DataTable
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        filterComponent={
          <FilterPopover
            anchorEl={anchorEl}
            onOpen={(event) => setAnchorEl(event.currentTarget)}
            onClose={() => setAnchorEl(null)}
            setAnchorEl={setAnchorEl}
          />
        }
        searchPlaceholder="Search by Name or Email"
        filterable
        customAction={
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/subadmin-management/add-subadmin")}
          >
            Add New Subadmin
          </Button>
        }
      />
    </div>
  );
};

export default SubAdminManagement;
