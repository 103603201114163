import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Switch,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormGroup,
  FormControlLabel,
  Modal,
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const UserDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location,"locationlocation")
  const user = location.state?.user || {
    name: "Jonathan Smith",
    nameId: "John1234",
    phone: "+1 234567",
    email: "jonathan.smith@gmail.com",
    address: "123, street, Abu Dhabi",
    joined: "10 November 2024",
    status: "Unblocked",
    lastLogin: "12:00 PM, 12 Nov 2024",
    nationality: "Default Nationality"

  };

  const membershipDetails = [
    {
      type: "Gold",
      pointsAvailable: 20000,
      currentLevyBalance: "$2000",
      pointsOwned: 50000,
    },
    {
      type: "Silver",
      pointsAvailable: 20000,
      currentLevyBalance: "$2000",
      pointsOwned: 50000,
    },
    {
      type: "Gold",
      pointsAvailable: 20000,
      currentLevyBalance: "$2000",
      pointsOwned: 50000,
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);

  const handleBlockUnblock = () => {
    setIsBlocking(user.status === "Unblocked");
    setShowModal(true);
  };

  const handleModalConfirm = () => {
    alert(`${isBlocking ? "Blocking" : "Unblocking"} user...`);
    setShowModal(false);
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };


  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <Box sx={{ padding: "20px" }}>
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          <Button
            variant="text"
            onClick={handleGoBack}
            sx={{ fontWeight: "normal" }}
          >
            <ArrowBackIosIcon/>
          </Button>
          User Details 
        </Typography>
        <Button
          variant="outlined"
          color={user.status === "Blocked" ? "success" : "error"}
          onClick={handleBlockUnblock}
          sx={{ padding: "10px 50px" }}
        >
          {user.status === "Blocked" ? "Unblock" : "Block"}
        </Button>
      </Box>

      {/* Basic Information Section */}
      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "20px",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
          >
            Basic Information
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormGroup>
              <FormControlLabel
                required={false}
                disabled={false}
                control={
                  <Switch
                    size={"medium"}
                    onChange={() => console.log("clicked")}
                    defaultChecked={false}
                    sx={{ color: "#3BC1CD" }}
                  />
                }
                label={"Autodebit"}
                labelPlacement={"start"}
              />
            </FormGroup>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Name
            </Typography>
            <Typography sx={{ color: "#3D3A3A", fontSize: "20px" }}>
              {user.name}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Name Id
            </Typography>
            <Typography sx={{ color: "#3D3A3A", fontSize: "20px" }}>
              {user.nameId}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Phone Number
            </Typography>
            <Typography sx={{ color: "#3D3A3A", fontSize: "20px" }}>
              {user.phone}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Email Address
            </Typography>
            <Typography sx={{ color: "#3D3A3A", fontSize: "20px" }}>
              {user.email}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Address
            </Typography>
            <Typography sx={{ color: "#3D3A3A", fontSize: "20px" }}>
              {user.address}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Joined On
            </Typography>
            <Typography sx={{ color: "#3D3A3A", fontSize: "20px" }}>
              {user.joined}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Status
            </Typography>
            <Typography sx={{ color: "#3D3A3A", fontSize: "20px" }}>
              {user.status}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Last Login time
            </Typography>
            <Typography sx={{ color: "#3D3A3A", fontSize: "20px" }}>
              {user.lastLogin}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Membership Details Section */}
      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ marginBottom: "20px" }}
        >
          Membership Details
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Membership Type</TableCell>
                <TableCell>Points available</TableCell>
                <TableCell>Current Levy Balance</TableCell>
                <TableCell>Points Owned</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {membershipDetails.map((membership, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontSize: "20px" }}>
                    {membership.type}
                  </TableCell>
                  <TableCell sx={{ fontSize: "24px" }}>
                    {membership.pointsAvailable}
                  </TableCell>
                  <TableCell sx={{ fontSize: "24px" }}>
                    {membership.currentLevyBalance}
                  </TableCell>
                  <TableCell sx={{ fontSize: "24px" }}>
                    {membership.pointsOwned}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Modal
        open={showModal}
        onClose={handleModalCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "32px", color: "#3D3A3A" }}
          >
            {isBlocking ? "Block User" : "Unblock User"}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontSize: "20px", color: "#3D3A3A" }}
          >
            Are you sure <br /> to {isBlocking ? "block" : "unblock"} this user?
          </Typography>
          <Box
            sx={{
              display: "flex",

              mt: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleModalCancel}
              sx={{
                mr: 2,
                width: "50%",
                borderRadius: "8px",
                border: "1px solid #9DA4AE",
                color: "#111",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={handleModalConfirm}
              sx={{
                width: "50%",
                backgroundColor: "#3BC1CD",
                borderRadius: "8px",
                color: "#fff",
                border: "none",
              }}
            >
              {isBlocking ? "Block" : "Unblock"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserDetails;
