import React, { useState } from "react";
import { TextField, Switch, Button } from "@mui/material";

const AddSubAdmin = () => {
  const [permissions, setPermissions] = useState({
    Dashboard: false,
    "User Management": false,
    "Room Management": false,
    "Testimonial Management": false,
    "Booking Management": false,
    "Finance Management": false,
    "Content Management": false,
  });

  const handlePermissionToggle = (key: string) => {
    setPermissions((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleCancel = () => {
    console.log("Cancelled");
    // Add navigation logic or reset logic here
  };

  const handleSubmit = () => {
    console.log("Form Submitted with Data:", permissions);
    // Add submit logic here
  };

  return (
    <div className="p-6 w-[70%]">
      <h2 className="text-lg font-bold mb-4">Add New Sub-Admin</h2>
      <div className="grid grid-cols-2 gap-4 mb-6">
        {/* Name Field */}
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          placeholder="Enter Full Name"
          InputProps={{
            style: {
              borderRadius: "8px",
            },
          }}
        />

        {/* Role Field */}
        <TextField
          label="Role"
          variant="outlined"
          fullWidth
          placeholder="Select Role"
          InputProps={{
            style: {
              borderRadius: "8px",
            },
          }}
        />
      </div>

      <div className="border rounded-lg p-4 bg-[#F8F8F8]">
        <div className="grid grid-cols-2 border-b pb-2 mb-2 font-bold">
          <span>Permission</span>
          <span className="text-right">View / Edit</span>
        </div>

        {Object.keys(permissions).map((key) => (
          <div
            key={key}
            className="grid grid-cols-2 items-center py-2 border-b last:border-0"
          >
            <span>{key}</span>
            <Switch
              checked={permissions[key]}
              onChange={() => handlePermissionToggle(key)}
              color="primary"
            />
          </div>
        ))}
      </div>

      <div className="flex justify-end mt-6 gap-4">
        {/* Cancel Button */}
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancel}
          sx={{
            borderRadius: "8px",
            padding: "8px 16px",
          }}
        >
          Cancel
        </Button>

        {/* Submit Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#3BC1CD",
            color: "#fff",
            borderRadius: "8px",
            padding: "8px 16px",
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddSubAdmin;
