// import React, { useState } from "react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Import Quill styles

// interface RichTextEditorProps {
//   value: string;
//   onChange: (value: string) => void;
// }

// const RichTextEditor: React.FC<RichTextEditorProps> = ({ value, onChange }) => {
//   console.log(typeof value, "valll");
//   return (
//     <ReactQuill
//       theme="snow"
//       value={value}
//       onChange={onChange}
//       style={{
//         // height: "200px", // Adjust height as needed
//         background: "#FFF",
//         borderRadius: "8px",
//         border: "1px solid #C4C4C4",
//       }}
//       modules={{
//         toolbar: [
//           ["bold", "italic", "underline"], // Text styling
//           [{ list: "ordered" }, { list: "bullet" }], // Lists
//           [{ align: [] }], // Alignment
//           ["clean"], // Clear formatting
//         ],
//       }}
//     />
//   );
// };

// export default RichTextEditor;
import React, { useEffect, useState } from "react";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type RichTextEditorProps = {
  name: string;
  onChange: (value: any) => void;
  placeholder?: string;
  value: string;
};

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  name,
  onChange,
  value,
  placeholder = "Enter details",
}) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  // Initialize editor state with the passed `value`
  useEffect(() => {
    if (value) {
      try {
        const blocksFromHTML = convertFromHTML(value);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      } catch (error) {
        console.error("Error initializing editor state:", error);
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [value]);

  // Handle editor changes
  // const handleEditorChange = (newEditorState: EditorState) => {
  //   setEditorState(newEditorState);

  //   // Convert the editor content to HTML and pass it to `onChange`
  //   const content = newEditorState.getCurrentContent();
  //   const html = stateToHTML(content);
  //   onChange({ target: { name, value: html } });
  // };

  const handleEditorChange = (newEditorState: EditorState) => {
  setEditorState(newEditorState);
};


  return (
    <div style={{ direction: "ltr" }} className="w-full bg-white p-2">
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        placeholder={placeholder}
        toolbar={{
          options: ["inline", "list", "textAlign", "link", "image"],
          inline: { options: ["bold", "italic", "underline"] },
          list: { options: ["unordered", "ordered"] },
          textAlign: { options: ["left", "center", "right"] },
          link: { defaultTargetOption: "_blank" },
          image: { defaultSize: { height: "auto", width: "100%" } },
        }}
      />
    </div>
  );
};

export default RichTextEditor;
