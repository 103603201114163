import React from 'react'

const DashboardPage = () => {
  
  const data = [
    { title: "Total Users", value: "2.5K", bgColor: "bg-[#A9EDF382]" },
    { title: "Active Users", value: "1.2K", bgColor: "bg-[#A9EDF382]" },
    { title: "Blocked Users", value: "50", bgColor: "bg-[#A9EDF382]" },
    { title: "Total Downloads", value: "50", bgColor: "bg-[#A9EDF382]" },
    { title: "Average Booking Value in Points", value: "2.5K", bgColor: "" },
    { title: "Total Booking Value", value: "1.2K", bgColor: "" },
    { title: "Total number of Booking requests", value: "50", bgColor: "" },
    { title: "Total number of Android users", value: "2.5K", icon: "android" },
    { title: "Total number of IOS users", value: "1.2K", icon: "apple" },
    { title: "Total number of Crashes", value: "50" },
    { title: "Total Downloads", value: "50" },
  ];




  return (
    <div className="p-6">
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 h-[133px]">
      {data.slice(0, 4).map((item, index) => (
        <div
          key={index}
          className={`p-4 rounded-md shadow-md ${item.bgColor} flex flex-col items-start`}
        >
          <span className="text-sm font-medium text-[#59A3AA]">
            {item.title}
          </span>
          <span className="text-3xl font-bold text-gray-800">{item.value}</span>
        </div>
      ))}
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mt-4 h-[160px]">
      {data.slice(4, 6).map((item, index) => (
        <div
          key={index}
          className={`p-4 rounded-md shadow-md ${item.bgColor} flex flex-col items-start`}
        >
          <span className="text-sm font-medium text-[#59A3AA]">
            {item.title}
          </span>
          <span className="text-3xl font-bold text-gray-800">{item.value}</span>
        </div>
      ))}
    </div>

    <div className="mt-4">
      <div className="p-4 border rounded-md shadow-md h-[160px]">
        <span className="text-sm font-medium text-[#59A3AA]">
          Total number of Booking requests
        </span>
        <span className="text-3xl font-bold text-gray-800 mt-2 block">
          50
        </span>
      </div>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-4 h-[170px]">
      {data.slice(7).map((item, index) => (
        <div
          key={index}
          className={`p-4 rounded-md shadow-md ${item.bgColor} flex flex-col items-start`}
        >
          <div className="flex items-center gap-2">
            {item.icon && (
              <span className="text-xl">
                {item.icon === "android" && (
                  <i className="fab fa-android text-[#0ABAB5]" />
                )}
                {item.icon === "apple" && (
                  <i className="fab fa-apple text-[#0ABAB5]" />
                )}
              </span>
            )}
            <span className="text-sm font-medium text-[#59A3AA]">
              {item.title}
            </span>
          </div>
          <span className="text-3xl font-bold text-gray-800">{item.value}</span>
        </div>
      ))}
    </div>
  </div>
  )
}

export default DashboardPage
