import React from "react";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, actionType }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-md">
        <h3 className="text-lg font-bold">
          {actionType === "block" ? "Block User" : "Unblock User"}
        </h3>
        <p className="mt-4">
          Are you sure you want to {actionType === "block" ? "block" : "unblock"} this user?
        </p>
        <div className="flex justify-end mt-6 gap-2">
          <button className="p-2 bg-gray-200 rounded" onClick={onClose}>
            Cancel
          </button>
          <button className="p-2 bg-blue-500 text-white rounded" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
