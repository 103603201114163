
import React from "react"
const Navbar = () => {
  return (
    <nav className=" p-4 flex justify-between items-center shadow-xl">
    <div className="text-lg font-bold"><img src="/assets/Aria-Logo.png" alt="Aria Logo" /></div>
  </nav>
  )
}

export default Navbar
