import React from "react";
import { Box, Typography, Grid, Paper, Button } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from "react-router-dom";


const BookingDetails = () => {

  const navigate = useNavigate();
const location = useLocation();
console.log(location, 'locationnnnn')


  const bookingDetails = location?.state?.user


  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Box sx={{ padding: "20px", margin: "0 auto" }}>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: "20px" }}>
      <Button
            variant="text"
            onClick={handleGoBack}
            sx={{ fontWeight: "normal" }}
          >
            <ArrowBackIosIcon/>
          </Button>
        Booking Details
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {/* Property Image */}
            <Box
              component="img"
              src={"/assets/demo-booking-property.png"}
              alt={bookingDetails.propertyName}
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
              }}
            />
            {/* Property Name */}
            <Typography
              sx={{ fontSize: "12px", fontWeight: "400", color: "#979797" }}
            >
              Property
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "700", color: "#3D3A3A" }}
            >
              {bookingDetails.propertyName}
            </Typography>
            {/* Property About */}
            <Typography
              variant="body2"
              sx={{ fontWeight: "400", fontSize: "16px", color: "#3D3A3A" }}
            >
              {bookingDetails.about}
            </Typography>
          </Paper>
        </Grid>

        {/* Right Section: Booking Information */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: "20px", fontSize: "24px", fontWeight: "400" }}
            >
              Basic Information
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Unit Name
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  {bookingDetails.unitName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Booked user name
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  {bookingDetails.bookedUserName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Check in Date
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  {bookingDetails.checkInDate}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Check out Date
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  {bookingDetails.checkOutDate}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Total Night
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  {bookingDetails.totalNight}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Total Guests
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  {bookingDetails.guests}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Points Required
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  {bookingDetails.pointsRequired}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Status
                </Typography>
                <Typography
                  sx={{
                    color:
                      bookingDetails.status === "Rejected"
                        ? "error.main"
                        : "success.main",
                    fontWeight: "bold",
                  }}
                >
                  {bookingDetails.status}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Remarks
                </Typography>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
                >
                  {bookingDetails.remarks}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingDetails;
