import React, { useState } from "react";
import { Button, Card, Modal, Tabs, Tab, Box } from "@mui/material";
import { Height } from "@mui/icons-material";

const tutorialTabs = ["Screen 1", "Screen 2", "Screen 3"];

const ImageManagement: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedTutorialTab, setSelectedTutorialTab] = useState(0);

  const handleTutorialTabChange = (
    _: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedTutorialTab(newValue);
  };

  const handleUploadClick = (title: string) => {
    setModalTitle(title);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box sx={{padding:"39px"}}>
      {/* Welcome Screen Section */}
      <Box mb={4}>
        <div className="flex justify-between items-center">
          <h2 className="text-[24px] font-normal text-[#004135]">
            Welcome Screen
          </h2>
          <Button
            variant="contained"
            style={{
              height: "fit-content",
              backgroundColor: "#3BC1CD",
              color: "#FFF",
            }}
            onClick={() => handleUploadClick("Welcome Screen")}
          >
            Upload Image
          </Button>
        </div>

        <Box
          style={{
            width: "255px",
            height: "230px",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#FFF",
            marginTop: "31px",
            marginBottom: "31px",
          }}
        >
          {/* Image Section */}
          <Box
            style={{
              width: "100%",
              overflow: "hidden",
              borderRadius: "6px",
            }}
          >
            <img
              src="/assets/resort-demo.png"
              alt="Card Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          {/* Text Section */}
          <Box
            style={{
              padding: "12px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                margin: 0,
                color: "#333",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              Balqis Residence, Palm Jumeirah
            </p>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                color: "#3BC1CD",
                fontWeight: "700",
              }}
            >
              Selected
            </p>
          </Box>
        </Box>
      </Box>
      <hr />

      {/* Tutorials Section */}
      <Box mb={4}>
        <div className="flex justify-between items-center">
          <h2 className="text-[24px] font-normal text-[#004135]">Tutorials</h2>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3BC1CD",
              color: "#FFF",
              marginTop: "10px",
            }}
            onClick={() =>
              handleUploadClick(
                `Tutorials - ${tutorialTabs[selectedTutorialTab]}`
              )
            }
          >
            Upload Image
          </Button>
        </div>
        <Tabs
          value={selectedTutorialTab}
          onChange={handleTutorialTabChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{
            style: { display: "none" }, // Hide the default indicator
          }}
        >
          {tutorialTabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab}
              style={{
                backgroundColor:
                  selectedTutorialTab === index ? "#3BC1CD" : "#FFF",
                color: selectedTutorialTab === index ? "#FFF" : "#000", // Change text color based on active/inactive
                border: "none",
                borderRadius: "8px",
                fontWeight: selectedTutorialTab === index ? "700" : "500",
                textTransform: "none", // Disable uppercase
                padding: "10px 16px",
                minWidth: "auto", // Prevent tabs from stretching
                marginRight: "12px",
                fontSize: "16px",
              }}
            />
          ))}
        </Tabs>
        <Box
          style={{
            width: "255px",
            height: "230px",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#FFF",
            marginTop: "31px",
            marginBottom: "31px",
          }}
        >
          {/* Image Section */}
          <Box
            style={{
              width: "100%",
              overflow: "hidden",
              borderRadius: "6px",
            }}
          >
            <img
              src="/assets/resort-demo.png"
              alt="Card Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          {/* Text Section */}
          <Box
            style={{
              padding: "12px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                margin: 0,
                color: "#333",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              Balqis Residence, Palm Jumeirah
            </p>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                color: "#3BC1CD",
                fontWeight: "700",
              }}
            >
              Selected
            </p>
          </Box>
        </Box>
      </Box>
      <hr />

      {/* Home Backdrop Section */}
      <Box mb={4}>
        <div className="flex justify-between items-center">
          <h2 className="text-[24px] font-normal text-[#004135]">
            Home Backdrop
          </h2>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3BC1CD",
              color: "#FFF",
              marginTop: "10px",
            }}
            onClick={() => handleUploadClick("Home Backdrop")}
          >
            Upload Image
          </Button>
        </div>
        <Box
          style={{
            width: "255px",
            height: "230px",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#FFF",
            marginTop: "31px",
            marginBottom: "31px",
          }}
        >
          {/* Image Section */}
          <Box
            style={{
              width: "100%",
              overflow: "hidden",
              borderRadius: "6px",
            }}
          >
            <img
              src="/assets/resort-demo.png"
              alt="Card Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          {/* Text Section */}
          <Box
            style={{
              padding: "12px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                margin: 0,
                color: "#333",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              Balqis Residence, Palm Jumeirah
            </p>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                color: "#3BC1CD",
                fontWeight: "700",
              }}
            >
              Selected
            </p>
          </Box>
        </Box>
      </Box>
      <hr />

      {/* Know More Section */}
      <Box mb={4}>
        <div className="flex justify-between items-center">
          <h2 className="text-[24px] font-normal text-[#004135]">Know More</h2>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3BC1CD",
              color: "#FFF",
              marginTop: "10px",
            }}
            onClick={() => handleUploadClick("Know More")}
          >
            Upload Image
          </Button>
        </div>

        <Box
          style={{
            width: "255px",
            height: "230px",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#FFF",
            marginTop: "31px",
            marginBottom: "31px",
          }}
        >
          {/* Image Section */}
          <Box
            style={{
              width: "100%",
              overflow: "hidden",
              borderRadius: "6px",
            }}
          >
            <img
              src="/assets/resort-demo.png"
              alt="Card Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          {/* Text Section */}
          <Box
            style={{
              padding: "12px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                margin: 0,
                color: "#333",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              Balqis Residence, Palm Jumeirah
            </p>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                color: "#3BC1CD",
                fontWeight: "700",
              }}
            >
              Selected
            </p>
          </Box>
        </Box>
      </Box>

      {/* Modal for Upload */}
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#FFF",
            padding: "20px",
            borderRadius: "10px",
            width: "612px",
          }}
        >
          <h2 className="text-center text-[32px] font-bold text-[#3D3A3A] mb-7">
            {modalTitle}
          </h2>
          <Box
            style={{
              border: "2px dashed #C4C4C4",
              borderRadius: "10px",
              padding: "53px",
              backgroundColor: "#A9EDF34D",
              display:"flex",
              flexDirection:"column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="/assets/fileUpload-icon.png" alt="upload icon" />
            <p className="text-sm font-bold "> Upload Image</p>
            <span className="text-xs font-normal text-[#3BC1CD]">
              Maximum size allowed: 5 MB, Format supported: JPEG,PNG, JPG only
            </span>
          </Box>
          <Box mt={3} display="flex" gap={1}>
            <Button
              variant="outlined"
              style={{ flex: "1" }}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#3BC1CD", color: "#FFF", flex: "1" }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ImageManagement;
