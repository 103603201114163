import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Popover, Typography, List, ListItem, ListItemText, Box } from "@mui/material";

const DashboardNavbar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  // Function to determine the title based on the current route
  const getTitle = () => {
    const path = location.pathname;

    if (path === "/user-management") return "User Management";
    if (path === "/profile") return "My Profile";
    if (path.startsWith("/user-details")) return "User Management";
    if (path.startsWith("/static-content")) return "Static Content Management";
    if (path === "/booking-management") return "Booking Management";
    if (path === "/room-management") return "Room Management";
    if (path === "/notifications-management") return "Notifications Management";
    if (path === "/subadmin-management") return "Sub Admin Management";
    if (path === "/testimonial-management") return "Testimonials Management";

    return "Dashboard"; // Default title
  };

  const title = getTitle();

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleProfile = () => {
    navigate("/profile"); // Navigate to the profile page
  };

  const handleNotificationClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  const notifications = [
    {
      title: "Booking Request",
      description: "John Smith sent the booking request",
      time: "2 hours ago",
    },
    {
      title: "Profile Updated!",
      description: "You have updated your profile",
      time: "2 hours ago",
    },
    {
      title: "Autodebit Disable Request",
      description: "John Smith sent the request",
      time: "2 hours ago",
    },
    {
      title: "Autodebit Disable Request",
      description: "John Smith sent the request",
      time: "2 hours ago",
    },
    {
      title: "Autodebit Disable Request",
      description: "John Smith sent the request",
      time: "2 hours ago",
    },
    {
      title: "Autodebit Disable Request",
      description: "John Smith sent the request",
      time: "2 hours ago",
    },
    {
      title: "Autodebit Disable Request",
      description: "John Smith sent the request",
      time: "2 hours ago",
    },
    {
      title: "Autodebit Disable Request",
      description: "John Smith sent the request",
      time: "2 hours ago",
    },
  ];

  return (
    <nav className="py-4 px-9 flex justify-between items-center shadow-md h-[85px]">
      <h1 className="text-[32px] font-bold">{title}</h1>
      <div className="flex items-center gap-4">
        {/* Notifications */}
        <div>
          <NotificationsIcon
            className="text-gray-600 cursor-pointer"
            onClick={handleNotificationClick}
          />
          <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                padding: 2,
                borderBottom: "1px solid #C4C4C4",
                fontWeight: "bold",
              }}
            >
              Notifications
            </Typography>
            <Box sx={{ width: 300 }}>
              {notifications.map((notification, index) => (
                <Box
                  key={index}
                  sx={{
                    padding: "12px 16px",
                    borderBottom: "1px solid #E0E0E0", // Slim line below each notification
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", fontSize: "14px", color: "#111" }}
                    >
                      {notification.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "#666",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {notification.description}
                    </Typography>
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "12px", color: "#999", whiteSpace: "nowrap" }}
                  >
                    {notification.time}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Popover>
        </div>
        {/* Profile */}
        <div
          onClick={handleProfile}
          className="text-[#004135] font-bold text-xl cursor-pointer"
        >
          Hi, John
        </div>
      </div>
    </nav>
  );
};

export default DashboardNavbar;
