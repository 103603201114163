import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface ResetPasswordModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleModalClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "24px" }}
      >
        Reset Password
      </DialogTitle>
      <DialogContent style={{ padding: "24px" }}>
        {/* New Password Field */}
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter New Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon style={{ color: "#C4C4C4" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          style={{
            marginBottom: "20px",
            border: "1px solid #C4C4C4",
            borderRadius: "10px",
          }}
        />
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Confirm New Password"
          type={showConfirmPassword ? "text" : "password"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon style={{ color: "#C4C4C4" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          style={{
            marginBottom: "20px",
            border: "1px solid #C4C4C4",
            borderRadius: "10px",
          }}
        />
      </DialogContent>

      {/* Modal Actions */}
      <DialogActions
        style={{
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px", // Adds some spacing between the buttons
        }}
      >
        <Button
          onClick={handleModalClose}
          variant="outlined"
          style={{
            color: "#111",
            fontWeight: "500",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            padding: "10px 20px",
            flex: 1, // Takes equal space
          }}
        >
          Dismiss
        </Button>
        <Button
          onClick={() => {
            console.log("Password reset initiated");
            handleModalClose();
          }}
          variant="contained"
          style={{
            backgroundColor: "#3BC1CD",
            color: "#FFF",
            fontWeight: "500",
            borderRadius: "8px",
            padding: "10px 20px",
            flex: 1, // Takes equal space
          }}
        >
          Reset Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordModal;
